export const environment = {
  production: false,
  baseUrl: 'http://dev-packages.unomain.com',
  shopApiUrl: 'https://testapi.unomain.com/shop/api',
  primeApiUrl: 'https://testapi.unomain.com/prime/api',
  authUrl: 'https://testidp.unomain.com/oauth/token',
  appsUrl: 'https://appstest.unomain.com',
  payUrl_paynamics: 'https://api.payserv.net/v1/rpf/transactions/rpf', //'https://ptiapps.paynamics.net/webpayment/default.aspx', //'https://testpti.payserv.net/webpayment/Default.aspx',i
  payUrl_ub: 'https://sith.unionbankph.com/UPAY/Whitelabel/', //'https://ubotpsentry-tst1.outsystemsenterprise.com/UPAY/Whitelabel/', 
  imageUrl: 'https://images.unomain.com/coxpchxeixfgmngjgrvov2/upl/',
  regional2: 'https://testregionalapi.unomain.com',
  ebankUrl: 'https://test-e-bank.unomain.net',
  apiEndpoints: {
    profiles: 'profiles',
    customers: 'customers',
    vendor: 'vendor',
    products: 'products',
    catalog: 'catalog',
    carts: 'carts',
    cartItems: 'cartitems',
    regional: 'regional',
  },
  origin: 'PCKGPH',
  recaptchaKey: '6Lf6cU0UAAAAADpyXK6b76Q3J7dmE6lekO-F_Vyy',
  paypalClientId: 'AbGJ0mA1xJcHDXp_iUl91MNZtN6Ml79WwL-GrDeeddbnSQh7ae5AYVCd56FOHHAI_S1Azt7tkSy7mh9k',
  creditCardChargePercent: 0.035,
  paypalChargePercent: 0.039,
  ub_uuid: '725BE83B-98B9-3D2B-8BF4-7E72D46D308E',
  ub_aes_key: '3f44462a23312a4364636525252a362f2f3f3f423765372f4434366433252345',
};
// ng build --configuration=staging
