import { SaveEditGuard } from './save-edit.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NeedAuthGuard } from './_shared/NeedAuthGuard ';
import { HomeComponent } from './home/home.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderCompletedComponent } from './order-completed/order-completed.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { OrderCancelledComponent } from './order-cancelled/order-cancelled.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'home/*', component: HomeComponent },
  { path: 'unsuccessful', component: OrderCancelledComponent },
  { path: 'catalog', component: CatalogComponent, canActivate: [] },
  { path: 'catalog/:entryType', component: CatalogComponent, canActivate: [] },
  { path: 'catalog/:entryType/:category', component: CatalogComponent, canActivate: [] },
  { path: 'cart', component: CartComponent, canActivate: [] },
  { path: 'checkout', component: CheckoutComponent, canActivate: [], canDeactivate: [] },
  { path: 'completed/:id', component: OrderCompletedComponent, canActivate: [] },
  { path: ':store/completed/:id', component: OrderCompletedComponent, canActivate: [NeedAuthGuard] },
  { path: 'completed/:id/:payment', component: OrderCompletedComponent, canActivate: [] },
  { path: 'products/:id', component: ProductViewComponent, canActivate: [] },
  { path: ':store', component: CatalogComponent, canActivate: [NeedAuthGuard] },
  { path: ':store/:page', component: CatalogComponent, canActivate: [NeedAuthGuard] },
  { path: ':store/:page/:category', component: CatalogComponent, canActivate: [NeedAuthGuard] },
  { path: '', redirectTo: 'catalog', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
